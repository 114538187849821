<template>
  <div class="root">
    <TheHeader v-if="hasValidUser" />
    <div
      v-if="isErrorNotFound"
      class="root__content root__content--message"
    >
      <h1 class="root__title">
        404
      </h1>
      <h2>Sorry... we could not find the page you are looking for.</h2>
      <br>
      <router-link
        to="/"
        class="root__error-link"
      >
        Go back to home
      </router-link>
    </div>
    <div
      v-else-if="canAccessResource"
      class="root__content"
    >
      <template v-if="showMenu">
        <TheAsideLeft class="root__aside-left" />
      </template>
      <div :class="['root__main', { 'root__main--full-width': !showMenu }]">
        <router-view />
      </div>
    </div>
    <div
      v-else-if="hasValidUser"
      class="root__content root__content--message"
    >
      <h2>Nothing to see yet 🙈</h2>
      <br>
      <h3>Ask for an invite from a colleague</h3>

      <h3>
        or
        <router-link
          to="/"
          class="root__error-link"
        >
          go to the homepage
        </router-link>
      </h3>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueTypes from 'vue-types'
import Sentry from '@/services/sentry'
import { canPermitAction } from '@/services/permissions'
import TheAsideLeft from '@/components/layout/TheAsideLeft'
// platform
import { PluginUtils } from '@/mixins/pluginUtils'
import FeatureFlags from '@/services/featureFlags'
import Utils from '@/services/utils'

const ERROR_CODE_NOT_FOUND = 404

export default {
  name: 'Root',

  components: {
    TheAsideLeft
  },

  mixins: [PluginUtils],

  props: {
    showMenu: VueTypes.bool.def(true)
  },

  data () {
    return {
      errorCode: null
    }
  },

  computed: {
    ...mapGetters({
      user: 'getCognitoUserData',
      isAuthorized: 'getIsAuthorized',
      isNotFound: 'getIsNotFound',
      activePermission: 'getActivePermission',
      activeWorkspace: 'getActiveWorkspace'
    }),

    /**
     * @returns {boolean}
     */
    canAccessResource () {
      return this.hasPermissions && this.isAuthorized
    },

    /**
     * @returns {boolean}
     */
    hasPermissions () {
      return this.user.permissions && this.user.permissions.length > 0
    },

    /**
     * @returns {boolean}
     */
    hasValidUser () {
      return this.user.email
    },

    /**
     * @returns {boolean}
     */
    isErrorNotFound () {
      return this.errorCode === ERROR_CODE_NOT_FOUND || this.isNotFound
    },

    /**
     * @returns {boolean}
     */
    isRootPath () {
      return this.$route.path === '/'
    }
  },

  watch: {
    /**
     */
    $route () {
      this.redirectToDefaultLibraryTypeIfRootPath()
      this.setErrorCode()
      this.setAuthorization()
      this.setIsNotFound(false)
    },

    /**
     */
    activePermission () {
      this.setAuthorization()
    }
  },

  async created () {
    await this.$featureFlagsManager.initialize()
    await this.fetchCognitoUserData()

    this.redirectToDefaultLibraryTypeIfRootPath()
    this.setErrorCode()

    if (this.user.email) {
      Sentry.identifyUserByEmail(this.user.email)

      this.$tracking.identifyUser(this.user)
      Utils.trackLogin(this.user, this.platform)
    }
  },

  methods: {
    ...mapActions(['fetchCognitoUserData', 'setIsAuthorized', 'setIsNotFound']),

    /**
     */
    setErrorCode () {
      const check = this.$route.meta.check

      if (check && !check()) {
        this.errorCode = 404
      } else {
        this.errorCode = null
      }
    },

    /**
     */
    redirectToDefaultLibraryTypeIfRootPath () {
      if (FeatureFlags.canUserAccessWorkspaces() && !this.activeWorkspace) {
        if (this.hasValidUser) {
          this.$router.push({ name: 'TheWorkspaces' })
        }
      } else {
        if (this.isRootPath) {
          this.$router.push({ name: 'LibraryStylesTypes' })
        }
      }
    },

    /**
     */
    setAuthorization () {
      const { roleFlag } = this.$route.meta
      const isAuthorized = roleFlag ? canPermitAction(roleFlag) : true

      this.setIsAuthorized(isAuthorized)
    }
  }
}
</script>

<style lang="scss" scoped>
$aside-left-width-small: 130px;
$root-width: calc(100vw - #{$aside-width});
$root-width-small: calc(100vw - #{$aside-left-width-small});
$root-height: calc(100vh - #{$main-header-height});

.root__content {
  display: flex;
  align-items: center;
  width: 100%;
  height: $root-height;
}

.root__content--message {
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.root__title {
  @include text-title-big;
}

.root__aside-left {
  width: $aside-width;
  min-width: spacing(27);
  height: 100%;

  @media (max-width: $media-query-l) {
    width: $aside-left-width-small;
  }
}

.root__main {
  width: $root-width;
  height: $root-height;

  @media (max-width: $media-query-l) {
    width: $root-width-small;
  }

  @media (max-width: $media-query-s) {
    padding-right: spacing(4);
  }
}

.root__main--full-width {
  width: 100%;
}

.root__error-link {
  color: $blue;
}
</style>
